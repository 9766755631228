import {
  Malowanie01,
  Malowanie02,
  Malowanie03,
  Oferta01,
  Oferta02,
  Oferta03,
  Oferta04,
  Oferta05,
  Plytki01,
  Plytki02,
  Plytki03
} from "../assets";

export const OfertaData = [
  [
    {
      title: "Układanie Płytek",
      img: Oferta01,
      text: `Potrzebujesz perfekcyjnego układu płytek? Wybierz nasze profesjonalne usługi! Zyskasz nie tylko precyzję i trwałość, ale także terminowość. Nasza doświadczona ekipa gwarantuje doskonałą jakość dostosowaną do Twoich potrzeb. Skorzystaj z naszego profesjonalizmu i ciesz się estetycznym wykończeniem swoich pomieszczeń. Skontaktuj się już dziś, aby rozpocząć transformację swojego wnętrza!`
    },
    {
      title: "Malowanie",
      img: Oferta02,
      text: `Potrzebujesz kompleksowej metamorfozy swojego mieszkania? Oferujemy profesjonalne usługi układania płytek i malowania! Nasza doświadczona ekipa zapewnia doskonałą jakość i estetyczne wykończenie. Zyskasz nie tylko precyzyjnie ułożone płytki, ale także kreatywne malowanie, które odświeży i nadadzą charakter Twoim wnętrzom. Terminowość, profesjonalizm i staranność to nasze atuty. Skontaktuj się z nami już dziś, aby rozpocząć transformację swojego domu! `
    },
    {
      title: "Gipsowanie",
      img: Oferta03,
      text: `Oferujemy kompleksowe usługi gipsowania! Nasza doświadczona ekipa gwarantuje perfekcyjne wykończenie ścian, przygotowane do malowania czy tapetowania. Zyskaj gładkie, estetyczne wnętrza bez stresu – terminowość i precyzja to nasza dewiza. Skontaktuj się z nami, aby rozpocząć transformację swojego domu! `
    },
    {
      title: "Docieplenie Poddasza",
      img: Oferta04,
      text: `Planujesz docieplenie poddasza? Wybierz nasze profesjonalne usługi, które gwarantują nie tylko efektywność energetyczną, ale także kompleksowe podejście do projektu. Dzięki zastosowaniu wysokiej jakości materiałów zapewnimy trwałą izolację termiczną, co przełoży się na realne oszczędności na rachunkach za ogrzewanie. Nasza doświadczona ekipa zajmie się całościowym procesem docieplenia, dbając o każdy detal. Skontaktuj się z nami już dziś, aby cieszyć się komfortem termicznym i poprawić efektywność energetyczną swojego domu!`
    },
    {
      title: "Docieplenie Elewacji",
      img: Oferta05,
      text: `Oferujemy kompleksowe docieplenie elewacji dla domu z efektem WOW! Nasza ekipa zagwarantuje nie tylko energooszczędność, ale też estetyczny look. Wybierając nas, zyskasz nie tylko trwałą izolację termiczną, ale także nowoczesny design, dostosowany do Twojego stylu. Nasze materiały najwyższej jakości przyczynią się nie tylko do oszczędności na ogrzewaniu, ale także podniosą atrakcyjność architektoniczną Twojego domu. Skontaktuj się, by nadać swojej elewacji nowy, świeży wygląd, łącząc funkcjonalność z estetyką! `
    }
  ],
  [
    {
      title: "Oferta",
      subtitle: "Zapoznaj się z Naszą ofertą"
    }
  ]
];
export const RealizacjaData = [
  [
    [
      {
        title: "Malowanie",
        main_img: Malowanie01,
        imgs: [Malowanie01, Malowanie02, Malowanie03]
      },
      {
        title: "Układanie Płytek",
        main_img: Plytki01,
        imgs: [Plytki01, Plytki02, Plytki03]
      }
    ]
  ],
  [
    {
      title: "Realizacje",
      subtitle: "Zobacz zdjęcia Naszych realizacji"
    }
  ]
];
