import { createClient, SanityClient } from "@sanity/client";

import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: "9ug2jirv",
  dataset: "strona_firmowa_v2_1_1",
  useCdn: false,
  apiVersion: "2023-11-13",
  token:
    "skqXnirHQ04iwtlEFr7GE7ciClihxdawUhaRHaiLkQrIe4TkkccFcIz54F3wWJDi3mp4UiWLtiZDZkU4GOfEcdu21Ql88WSc4Zt2uLyXU8SYvVRTP049QeoCAzC7k8BAAJfxiakXXbFneoNRxlsEEVSG6Bss7lcdrybvU9RMkjwPcRUGQPdU"
});

const builder = imageUrlBuilder(client);

export const urlFor = source => builder.image(source);
