import { useRef, useEffect, useState } from "react";
import { useInView } from "react-hook-inview";
import "./App.css";
import {
  Nawigacja,
  Header,
  About,
  Oferta,
  FirmaWLiczbach,
  Kontakt,
  Footer,
  Realizacje
} from "./containers";
import { client, urlFor } from "./client";
import { No_LOGO, favicon } from "./assets";
import ReactGA from "react-ga4";
import posthog from "posthog-js";
import { CookieBanner, PolitykaPrywatnosci } from "./components";
import { ustawienia } from "./libs";

//Ciastka
posthog.init("phc_It2CeekK28CNy1Mptrd6ARQXJruy8ErUEFkJDbGYBcD", {
  api_host: "https://eu.posthog.com"
});

function App() {
  const AboutRef = useRef(null);
  const OfferRef = useRef(null);
  const KontaktRef = useRef(null);
  const RealizacjeRef = useRef(null);
  const [LiczbyRef, czyStartowacLicznik] = useInView({
    threshold: 0.5
  });

  const [politykaPrywatnosciPage, setpolitykaPrywatnosciPage] = useState(false);

  return (
    <div className="App">
      {politykaPrywatnosciPage &&
        <PolitykaPrywatnosci
          setpolitykaPrywatnosciPage={setpolitykaPrywatnosciPage}
          Nazwa_Firmy={ustawienia[0].dane_firmy[0].nazwa_firmy}
          Nazwa_Ulicy={ustawienia[0].dane_firmy[0].ulica}
          Nr_Budynku={ustawienia[0].dane_firmy[0].nr_budynku}
          Telefon={ustawienia[0].dane_firmy[0].telefon}
          Kod_Pocztowy={ustawienia[0].dane_firmy[0].kod_pocztowy}
          Miejscowosc={ustawienia[0].dane_firmy[0].miejscowosc}
          Nip={ustawienia[0].dane_firmy[0].nip}
          Mail={ustawienia[0].dane_firmy[0].mail}
        />}

      {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing()
        ? null
        : <CookieBanner
            setpolitykaPrywatnosciPage={setpolitykaPrywatnosciPage}
          />}
      <Nawigacja
        AboutRef={AboutRef}
        OfferRef={OfferRef}
        KontaktRef={KontaktRef}
        RealizacjeRef={RealizacjeRef}
        Telefon={ustawienia[0].dane_firmy[0].telefon}
        FacebookLink={"facebook"}
        InstagramLink={"instagram"}
        YouTubeLink={"youtube"}
      />
      <Header
        AboutRef={AboutRef}
        OfferRef={OfferRef}
        KontaktRef={KontaktRef}
        RealizacjeRef={RealizacjeRef}
        Logo={ustawienia[0].dane_firmy[0].logo_firmy}
        Nazwa_Firmy={ustawienia[0].dane_firmy[0].nazwa_firmy}
      />
      <About AboutRef={AboutRef} />
      <Oferta OfferRef={OfferRef} />
      <Realizacje RealizacjeRef={RealizacjeRef} />
      <FirmaWLiczbach
        czyStartowacLicznik={czyStartowacLicznik}
        LiczbyRef={LiczbyRef}
      />
      <Kontakt KontaktRef={KontaktRef} />
      <Footer
        Nazwa_Firmy={ustawienia[0].dane_firmy[0].nazwa_firmy}
        Nazwa_Ulicy={ustawienia[0].dane_firmy[0].ulica}
        Nr_Budynku={ustawienia[0].dane_firmy[0].nr_budynku}
        Telefon={ustawienia[0].dane_firmy[0].telefon}
        Kod_Pocztowy={ustawienia[0].dane_firmy[0].kod_pocztowy}
        Miejscowosc={ustawienia[0].dane_firmy[0].miejscowosc}
        Nip={ustawienia[0].dane_firmy[0].nip}
        Mail={ustawienia[0].dane_firmy[0].mail}
        Logo={ustawienia[0].dane_firmy[0].logo_firmy}
        FacebookLink={"facebook"}
        InstagramLink={"instagram"}
        YouTubeLink={"youtube"}
      />
    </div>
  );
}

export default App;
