import React, { useState, useEffect, useRef, useMemo } from "react";
import { useInView } from "react-hook-inview";
import "./SingleCounter.css";
import { urlFor } from "../../client";

const SingleCounter = ({ title, img, licznik, czyStartowacLicznik }) => {
  // const [handlerIsActive, sethandlerIsActive] = useState(false);

  // const visibleRef = useRef(false);
  // const [licznikMax, setlicznikMax] = useState(licznik);
  // const [timer, setTimer] = useState(0);
  // const [intervalTime, setintervalTime] = useState(1000);
  // const increment = useRef(null);

  // const handleStart = () => {
  //   sethandlerIsActive(true);
  //   if (licznikMax >= 500) {
  //     increment.current = setInterval(() => {
  //       setTimer((timer) => timer + 1);
  //     }, 1);
  //   } else {
  //     increment.current = setInterval(() => {
  //       setTimer((timer) => timer + 1);
  //     }, 10);
  //   }
  // };

  // const handleReset = () => {
  //   clearInterval(increment.current);
  // };

  // if (timer >= licznikMax) {
  //   handleReset();
  // }

  // useEffect(() => {
  //   if (czyStartowacLicznik) {
  //     sethandlerIsActive(true);
  //     if (!handlerIsActive) {
  //       handleStart();
  //     }
  //     if (timer >= licznikMax) {
  //       setTimer(licznikMax);
  //     }
  //   }
  // }, [czyStartowacLicznik, handlerIsActive]);

  return (
    <div className="singlecounter__kontener">
      <div className="singlecounter-sekcje-obok-siebie">
        <div className="singlecounter-icon">
          <img src={img} alt={title} />
        </div>
        <div className="singlecounter-licznik">
          <p>
            {licznik}
            {/* {timer} */}
          </p>
        </div>
      </div>
      <div className="singlecounter-tytul">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default SingleCounter;
