import { No_LOGO } from "../assets";

export const ustawienia = [
  {
    dane_firmy: [
      {
        nazwa_firmy: "Twoja-Nazwa",
        telefon: "+48 726-472-762",
        logo_firmy: No_LOGO,
        nip: "949-226-49-26",
        mail: "kontakt@twojamarkawsieci.pl",
        miejscowosc: "Miejscowość ",
        ulica: "Nazwa Ulicy",
        nr_budynku: "15",
        kod_pocztowy: "00-000"
      }
    ]
  }
];
