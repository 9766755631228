import React, { useState, useEffect } from "react";
import "./CookieBanner.css";
import posthog from "posthog-js";
import PolitykaPrywatnosci from "../PolitykaPrywatnosci/PolitykaPrywatnosci";

const CookieBanner = ({ setpolitykaPrywatnosciPage }) => {
  const [showCookieBanner, setshowCookieBanner] = useState(true);

  const acceptCookies = () => {
    posthog.opt_in_capturing();
    setshowCookieBanner(false);
  };

  const declineCookies = () => {
    posthog.has_opted_out_capturing();
  };

  const openPolitykaPrywatnosc = () => {};

  useEffect(() => {}, []);

  return (
    <>
      {showCookieBanner && (
        <div className="cookiebanner__kontener">
          <div className="cookieBanner-paragraph__section">
            <p>
              Ta strona korzysta z plików cookie. Sprawdź naszą
              <a
                onClick={() => {
                  setpolitykaPrywatnosciPage(true);
                }}>
                {" "}
                politykę prywatności
              </a>
              , żeby dowiedzieć się więcej.
            </p>
          </div>
          <div className="cookieBanner-button__section">
            <button onClick={acceptCookies}>rozumiem</button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
