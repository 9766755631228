import React from "react";
import "./Footer.css";
import { SocialMediaIcons } from "../../components";
import { AiOutlineMail } from "react-icons/ai";

const Footer = ({
  Nazwa_Firmy,
  Nazwa_Ulicy,
  Nr_Budynku,
  Telefon,
  Kod_Pocztowy,
  Miejscowosc,
  Nip,
  Mail,
  Logo,
  FacebookLink,
  InstagramLink,
  YouTubeLink
}) => {
  return (
    <div className="footer__kontener">
      <div className="footer__kontener-zawartosc sekcja-padding">
        <div className="footer__logo">
          <img src={Logo} alt={`Logo ${Nazwa_Firmy}`} />
        </div>

        <div className="footer__call">
          <p>Zadzwoń do nas</p>
          <p>
            <a href={`tel:${Telefon}`}>{Telefon}</a>
          </p>
        </div>
        {(FacebookLink || InstagramLink || YouTubeLink) && (
          <div className="socialMediaIcons__mobile">
            <SocialMediaIcons
              FacebookLink={FacebookLink}
              InstagramLink={InstagramLink}
              YouTubeLink={YouTubeLink}
            />
          </div>
        )}

        <div className="footer__adres">
          <p>
            {Nazwa_Firmy === null || Nazwa_Firmy === undefined
              ? "Twoja-Firma"
              : Nazwa_Firmy}
          </p>
          <p>
            {Nazwa_Ulicy === undefined || Nazwa_Ulicy === null
              ? ""
              : `${Nazwa_Ulicy} ${Nr_Budynku}`}
          </p>
          <p>
            {Kod_Pocztowy} {Miejscowosc}
          </p>
          {Nip === undefined || Nip === null ? "" : <p>{Nip}</p>}

          <p>
            <span>{Mail ? <AiOutlineMail /> : ""}</span> {Mail}
          </p>
        </div>
        <div className="footer__copyright">
          <p>{`Copyright © ${Nazwa_Firmy} 2024 All Rights Reserved`}</p>
        </div>
        <div className="footer__realizacja">
          <p>
            Realizacja{" "}
            <a href="https://twojamarkawsieci.pl" target="_blank">
              twojamarkawsieci.pl
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
