import React, { useState, useEffect } from "react";
import "./Oferta.css";
import { OfertaModule } from "../../components";
import { OfertaData } from "../../libs";
import { client } from "../../client";

const Oferta = ({ OfferRef }) => {
  return (
    <div className="oferta__kontener" ref={OfferRef}>
      <div className="oferta__zawartosc sekcja-padding">
        <div className="title__h2">
          <h2>{OfertaData[1][0].title}</h2>

          <span></span>
        </div>
        <div className="subtitle__h4">
          <h4>{OfertaData[1][0].subtitle}</h4>
        </div>
        <div className="oferta__kontener-zawartosci">
          {OfertaData[0].map((data, index) => {
            return (
              <OfertaModule
                key={index + " Moduł Oferty"}
                img={data.img}
                title={data.title}
                opis={data.text}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Oferta;
