import { About01 } from "../assets";

export const AboutDATA = [
  {
    title: "O Firmie",
    subtitle: "Podziel się historią Twojej firmy.",
    text: `O Tobie – Twej firmie i marce osobistej. Strona Internetowa
     jest bazą wiedzy na temat Twojej osoby, jak również tego, czym się zajmujesz, w czym możesz pomóc, co warto zrobić razem z Tobą. Weź pod uwagę, że słowa te czytasz właśnie na stronie internetowej – skorzystaj z potencjału tej formy komunikacji wolnej od zawirowań oraz kaprysów algorytmów stosowanych w mediów społecznościowych.`,
    img: About01
  }
];
