import React, { useEffect, useState } from "react";
import "./About.css";
import { No_IMG } from "../../assets";
import { client, urlFor } from "../../client";
import { BodyText } from "../../components";
import { AboutDATA } from "../../libs";

const About = ({ AboutRef }) => {
  return (
    <div className="about__kontener sekcja-padding" ref={AboutRef}>
      <div className="about__text-kontener">
        <div className="about__title-section">
          <div className="title__h2">
            <h2>{AboutDATA[0].title}</h2>
            <span></span>
          </div>
          <div className="subtitle__h4">
            <h4>{AboutDATA[0].subtitle}</h4>
          </div>
          <div className="about__text-section">
            <p>{AboutDATA[0].text}</p>
          </div>
        </div>
      </div>
      <div className="about__img-kontener">
        <img src={AboutDATA[0].img} alt={AboutDATA[0].title} />
      </div>
    </div>
  );
};

export default About;
