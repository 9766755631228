import { Ikona01, Ikona02, Ikona03, Ikona04 } from "../assets";

export const LicznikData = [
  {
    title: "Kilometrów Polamowanych Ścian",
    counter: "185",
    icon: Ikona01
  },
  {
    title: "Ton Położonego Gipsu",
    counter: "210",
    icon: Ikona03
  },
  {
    title: "Zrealizowanych Zleceń",
    counter: "1800",
    icon: Ikona03
  },
  {
    title: "Zadowolonych Klientów",
    counter: "1500",
    icon: Ikona04
  }
];

export const LicznikNames = [
  {
    title: "Miara Naszych Sukcesów",
    subtitle: "Liczb mówią za Nas"
  }
];
