import React, { useState, useEffect } from "react";
import "./Header.css";
import { Menu, SliderV1 } from "../../components/";
import { client, urlFor } from "../../client";
import { No_IMG } from "../../assets";
import { SliderDATA_SANITY } from "../../libs";

const Header = ({
  AboutRef,
  OfferRef,
  KontaktRef,
  RealizacjeRef,
  Logo,
  Nazwa_Firmy
}) => {
  let SliderLENGTH;

  const SliderTITLE = [];
  const SliderTEXT = [];
  const SliderIMG = [];
  SliderDATA_SANITY.map((data, index) => {
    SliderTITLE.push(data.slider_title);
    SliderTEXT.push(data.slider_text);
    if (data.slider_img === null || data.slider_img === undefined) {
      SliderIMG.push(No_IMG);
    } else {
      SliderIMG.push(data.slider_img);
    }
  });
  SliderLENGTH = SliderDATA_SANITY.length;

  return (
    <div className="header__kontener">
      <div className="header__top-section sekcja-padding_nawigacja">
        <div className="header__logo">
          <img src={Logo} alt={`Logo ${Nazwa_Firmy}`} />
          <p>
            <span>{Nazwa_Firmy}</span>
          </p>
        </div>
        <Menu
          AboutRef={AboutRef}
          OfferRef={OfferRef}
          KontaktRef={KontaktRef}
          RealizacjeRef={RealizacjeRef}
        />
      </div>
      <SliderV1
        SliderTITLE={SliderTITLE}
        SliderTEXT={SliderTEXT}
        SliderIMG={SliderIMG}
        SliderDATA_SANITY={SliderDATA_SANITY}
        SliderLENGTH={SliderLENGTH}
      />
    </div>
  );
};

export default Header;
