import { Slider01 } from "../assets";

export const SliderDATA_SANITY = [
  {
    slider_title:
      "Budujemy Twoje marzenia - Solidność, Bezpieczeństwo, Doskonałość",
    slider_text:
      "Remont z nami to czysta przyjemność. Dbamy o każdy detal, zapewniając harmonię i komfort. Zaufajcie Nam, a Wasz dom stanie się spełnieniem marzeń",
    slider_img: Slider01
  }
];
