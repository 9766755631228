import React, { useState } from "react";
import "./Realizacja.css";
import { No_IMG } from "../../assets";
import { urlFor } from "../../client";
import { HiArrowLongRight } from "react-icons/hi2";
import { RiSearchEyeLine } from "react-icons/ri";
import { RealizacjaWiecej } from "../../components";

const Realizacja = ({ title, img_main, imgs }) => {
  const [realizacjaPhotoData, setrealizacjaPhotoData] = useState(false);

  // console.log(imgs);
  return (
    <>
      <div
        className="realizacja__kontener ofertamodule__kontener"
        role="button"
        onClick={() => setrealizacjaPhotoData(true)}>
        <div
          className="realizacja__img-section ofertamodule__img-section"
          style={{
            backgroundImage: `url(${
              img_main === undefined ||
              img_main === null ||
              !img_main ||
              img_main === 0
                ? No_IMG
                : img_main
            })`
          }}>
          <div className="realizacja__img-section__overlay ofertamodule__img-section__overlay"></div>
          <div className="realizacja__overlay-text">
            <span>
              <RiSearchEyeLine />
            </span>
            <p>Zobacz więcej</p>
          </div>
          <div className="realizacja__title-section ofertamodule__title-section">
            <h3>{title}</h3>
            <div className="realizacja__arrow-section">
              <span>
                <HiArrowLongRight />
              </span>
            </div>
          </div>
        </div>
      </div>
      {realizacjaPhotoData && (
        <RealizacjaWiecej
          imgs={imgs}
          title={title}
          setrealizacjaPhotoData={setrealizacjaPhotoData}
        />
      )}
    </>
  );
};

export default Realizacja;
