import React from "react";
import "./RealizacjaWiecej.css";
import { urlFor } from "../../client";
import { IoMdCloseCircleOutline } from "react-icons/io";

const RealizacjaWiecej = ({ imgs, title, setrealizacjaPhotoData }) => {
  return (
    <>
      <div className="realizacjaWiecej__overlay"></div>
      <div className="realizacjaWiecej__kontener">
        <div className="realizacjaWiecej__head">
          <div className="realizacjaWiecej__head-title title__h2">
            <h3>{title}</h3>
            <span></span>
          </div>
          <div className="realizacjaWiecej__head-button">
            <button onClick={() => setrealizacjaPhotoData(false)}>
              <IoMdCloseCircleOutline />
            </button>
          </div>
        </div>
        <div className="realizacjaWiecej__Lista-img">
          {imgs === null || imgs === undefined
            ? ""
            : imgs.map((data, index) => {
                return (
                  <div
                    className="realizacjaWiecej__img__kontener"
                    key={"nr obrazka " + index}>
                    <img src={data} alt={title} />
                  </div>
                );
              })}
        </div>
        <div className="text_kontener"></div>
      </div>
    </>
  );
};

export default RealizacjaWiecej;
