import React, { useState, useEffect } from "react";
import "./FirmaWLiczbach.css";
import { No_LOGO } from "../../assets";
import { client, urlFor } from "../../client";
import { SingleCounter } from "../../components";
import { LicznikData, LicznikNames } from "../../libs";

const FirmaWLiczbach = ({ SekcjaLicznik, czyStartowacLicznik, LiczbyRef }) => {
  return (
    <div className="firmawliczbach__kontener" ref={LiczbyRef}>
      <div className="firmawliczbach__zawartosc__kontener sekcja-padding">
        <div className="title__h2">
          <h2>{LicznikNames[0].title}</h2>

          <span></span>
        </div>
        <div className="subtitle__h4">
          <h4>{LicznikNames[0].subtitle}</h4>
        </div>
        <div className="firmawliczbach__counter-lista__kontener">
          {LicznikData.map((data, index) => {
            return (
              <SingleCounter
                key={data.title + index}
                title={data.title}
                img={data.icon}
                licznik={data.counter}
                czyStartowacLicznik={czyStartowacLicznik}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FirmaWLiczbach;
