import React, { useState, useEffect } from "react";
import "./SliderV1.css";
import { No_IMG } from "../../assets";

const SliderV1 = ({ SliderTITLE, SliderTEXT, SliderIMG, SliderLENGTH }) => {
  const [sliderIndex, setsliderIndex] = useState(0);
  const SliderV1__titleSlide = document.getElementById("SliderV1__titleSlide");
  const SliderV1__paragraphSlide = document.getElementById(
    "SliderV1__paragraphSlide"
  );

  const classDelete = () => {
    SliderV1__titleSlide.classList.remove("SliderV1__titleSlide");
    SliderV1__paragraphSlide.classList.remove("SliderV1__paragraphSlide");
  };

  const classAdd = () => {
    SliderV1__titleSlide.classList.add("SliderV1__titleSlide");
    SliderV1__paragraphSlide.classList.add("SliderV1__paragraphSlide");
  };

  setTimeout(() => {
    if (
      SliderV1__titleSlide !== null &&
      SliderV1__titleSlide.className === "SliderV1__titleSlide"
    ) {
      SliderV1__titleSlide.classList.remove("SliderV1__titleSlide");
      SliderV1__paragraphSlide.classList.remove("SliderV1__paragraphSlide");
    }
  }, 2500);

  useEffect(() => {
    setTimeout(() => {
      if (
        SliderV1__titleSlide !== null &&
        SliderV1__titleSlide.className === "SliderV1__titleSlide"
      ) {
        SliderV1__titleSlide.classList.remove("SliderV1__titleSlide");
        SliderV1__paragraphSlide.classList.remove("SliderV1__paragraphSlide");
      }
    }, 2500);
    if (
      SliderV1__titleSlide !== null &&
      SliderV1__titleSlide.className === ""
    ) {
      SliderV1__titleSlide.classList.add("SliderV1__titleSlide");
      SliderV1__paragraphSlide.classList.add("SliderV1__paragraphSlide");
    }
    setTimeout(() => {
      if (
        SliderV1__titleSlide !== null &&
        SliderV1__titleSlide.className === ""
      ) {
        SliderV1__titleSlide.classList.add("SliderV1__titleSlide");
        SliderV1__paragraphSlide.classList.add("SliderV1__paragraphSlide");
      }

      setsliderIndex(sliderIndex + 1);
    }, 6500);
    if (sliderIndex >= SliderLENGTH) {
      setsliderIndex(0);
    }
  }, [sliderIndex]);

  return (
    <div className="sliderV1__kontener">
      <div className="Slide_img-overlay"></div>

      <div className="Slide_img">
        <img src={SliderIMG[sliderIndex]} alt={SliderTITLE[sliderIndex]} />
      </div>
      <div className="sliderV1__pozostalaZawartosc-kontener sekcja-padding">
        <div className="sliderV1__title ">
          <h1 id="SliderV1__titleSlide" className="SliderV1__titleSlide">
            {SliderTITLE[sliderIndex]}
          </h1>
        </div>
        <div className="sliderV1__pozostalaZawartosc-paragraph">
          <p id="SliderV1__paragraphSlide" className="SliderV1__paragraphSlide">
            {SliderTEXT[sliderIndex]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SliderV1;
