import React from "react";
import urlBuilder from "@sanity/image-url";

import BlockContent from "@sanity/block-content-to-react";
import { urlFor } from "../../client";

function BodyText({ doc }) {
  const serializer = {
    types: {
      mainImage: (props) => (
        <figure>
          <img
            src={urlFor(props.node.asset).width(600).url()}
            alt={props.node.alt}
          />

          <figcaption>{props.node.caption}</figcaption>
        </figure>
      ),
      block: (props) => {
        const style = props.node.style || "normal";

        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, "");
          return React.createElement(`h${level}`, {}, props.children);
        }

        return style === "blockquote" ? (
          <blockquote>– {props.children}</blockquote>
        ) : (
          <p>{props.children}</p>
        );
      },
      code: (props) => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      )
    },
    list: (props) =>
      props.type === "bullet" ? (
        <ul>{props.children}</ul>
      ) : (
        <ol>{props.children}</ol>
      ),
    listItem: (props) =>
      props.type === "bullet" ? (
        <li>{props.children}</li>
      ) : (
        <li>{props.children}</li>
      ),
    marks: {
      strong: (props) => <strong>{props.children}</strong>,
      em: (props) => <em>{props.children}</em>,
      code: (props) => <code>{props.children}</code>,
      sup: (props) => <sup>{props.children}</sup>,
      sub: (props) => <sub>{props.children}</sub>
    }
  };

  return (
    <div className="blockContent">
      <BlockContent blocks={doc.blockContent} serializers={serializer} />
    </div>
  );
}

export default BodyText;
