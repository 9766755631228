import React from "react";
import "./MenuMobilne.css";

const MenuMobilne = ({ AboutRef, OfferRef, KontaktRef, RealizacjeRef }) => {
  const hanldeScroll = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="menuMobilne__kontener sekcja-padding_nawigacja">
      <p>
        <a href="#">Strona Główna</a>
      </p>
      <p
        onClick={() => {
          hanldeScroll(AboutRef);
        }}>
        <a href="#firmie">O firmie</a>
      </p>

      <p
        onClick={() => {
          hanldeScroll(OfferRef);
        }}>
        <a href="#oferta">Oferta</a>
      </p>
      <p
        onClick={() => {
          hanldeScroll(RealizacjeRef);
        }}>
        <a href="#realizacje">Realizacje</a>
      </p>

      <p
        onClick={() => {
          hanldeScroll(KontaktRef);
        }}>
        <a href="#kontakt">Kontakt</a>
      </p>
    </div>
  );
};

export default MenuMobilne;
