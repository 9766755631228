import React from "react";
import "./SocialMediaIcons.css";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

const SocialMediaIcons = ({ FacebookLink, InstagramLink, YouTubeLink }) => {
  return (
    <>
      {FacebookLink && (
        <a href={FacebookLink}>
          <FaFacebookF />
        </a>
      )}
      {InstagramLink && (
        <a href={InstagramLink}>
          <FaInstagram />
        </a>
      )}
      {YouTubeLink && (
        <a href={YouTubeLink}>
          <FaYoutube />
        </a>
      )}
    </>
  );
};

export default SocialMediaIcons;
