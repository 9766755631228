import React, { useState, useEffect } from "react";
import "./Realizacje.css";
import { No_IMG } from "../../assets";
import { client, urlFor } from "../../client";
import { Realizacja } from "../../components";
import { RealizacjaData } from "../../libs";

const Realizacje = ({ RealizacjeRef }) => {
  const [Realizacje, setRealizacje] = useState([]);
  const [realiacjaData, setrealiacjaData] = useState([]);
  const [RealizacjeZdjecia, setRealizacjeZdjecia] = useState([]);

  const RealizacjeData = [
    {
      title: "Płytkarstwo",
      img_main: No_IMG,
      imgs: [No_IMG, No_IMG]
    },
    {
      title: "Malowanie Ścian",
      img_main: No_IMG,
      imgs: [No_IMG, No_IMG]
    }
  ];

  return (
    <div className="realizacje__kontener sekcja-padding" ref={RealizacjeRef}>
      <div className="about__title-section">
        <div className="title__h2">
          <h2> {RealizacjaData[1][0].title}</h2>

          <span></span>
        </div>
        <div className="subtitle__h4">
          <h4>{RealizacjaData[1][0].subtitle}</h4>
        </div>
      </div>
      <div className="lista-realizacji__kontener oferta__kontener-zawartosci">
        {RealizacjaData[0][0].map((data, index) => {
          return (
            <Realizacja
              key={index + data.title}
              title={data.title}
              img_main={data.main_img}
              imgs={data.imgs}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Realizacje;
